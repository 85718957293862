.sales_main {
    margin-top: 32px;
    padding-bottom: 32px;
    @media (max-width: 767px) {
        margin-top: 24px;
        padding-bottom: 24px;
    }
    .upload_offer_btn {
        @media (max-width: 767px) {
            margin-top: 24px;
        }
    }
    .order_track {
        .details_block {
            margin-bottom: 18px;
        }
    }
}
.trackStatus_block {
    padding: 38px 36px 70px 36px;
    border: 1px solid #e2e3e4;
    @media (max-width: 767px) {
        padding: 24px 16px 40px 16px;
        max-width: 100%;
        margin: 0 auto;
    }
}

.track_flow {
    margin-top: 40px;
    @media (max-width: 767px) {
        margin-top: 20px;
    }
    .progress_bar {
        flex-direction: row;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        li {
            row-gap: 12px;
            flex-direction: column;
            padding-right: 10px;
            @media (max-width: 767px) {
                flex-direction: row;
                columns: 12px;
            }
            .step_data {
                .step_info {
                    @include font_18_400;
                }
                .step_date {
                    @include font_14_400;
                    color: $dark_grey;
                }
            }
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                top: 8.3px;
                left: -100%;
                z-index: -1;
                border-bottom: 3px dashed #c9cacc;
                @media (max-width: 767px) {
                    left: 0px;
                    border-bottom: 0px;
                    border-left: 3px dashed #c9cacc;
                }
            }
            &:first-child {
                .step_data {
                    .step_info {
                        margin-top: 0px;
                    }
                }
                &::after {
                    content: none;
                }
            }
            &:nth-child(2) {
                &::after {
                    @media (max-width: 767px) {
                        border-bottom: 0px;
                        z-index: 1;
                        width: auto;
                        border-left: 3px solid #f57e20;
                        height: 42%;
                        top: -78%;
                        left: 8px;
                    }
                }
            }
            &.active {
                &:first-child {
                    &::before {
                        border-color: #f57e20 !important;
                        border-width: 5px !important;
                    }
                }
                &::after {
                    z-index: -1;
                    @media (max-width: 767px) {
                        height: 100%;
                        border-bottom: 0px;
                        border-left: 3px solid #f57e20;
                        top: -100px;
                        left: 8px;
                        width: 100%;
                        z-index: 1;
                    }
                }
                &::before {
                    border-color: #f57e20 !important;
                    border-width: 5px !important;
                }
                .fix_border {
                    &::before {
                        content: "";
                        width: 100%;
                        position: absolute;
                        left: -100%;
                        border-bottom: 3px solid #f57e20;
                    }
                }
            }
            &:last-child {
                .fix_border {
                    border: none;
                }
            }
        }
    }
}
span.fix_border {
    position: absolute;
    border-bottom: 3px dashed #c9cacc;
    width: 100%;
    left: 0px;
    top: 8px;
    @media (max-width: 767px) {
        border-bottom: 0px;
        height: 100%;
        left: 8px;
        width: 1px;
        border-left: 3px dashed #c9cacc;
    }
}
.itemsShows {
    display: block !important;
}
.UploadOfferShow {
    display: flex !important;
}
