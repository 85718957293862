// PT Sans font start
@font-face {
    font-family: "PT Sans bold";
    src: url("../../fonts/ptSans/PTSans-Bold.eot");
    src: url("../../fonts/ptSans/PTSans-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ptSans/PTSans-Bold.woff2") format("woff2"),
        url("../../fonts/ptSans/PTSans-Bold.woff") format("woff"),
        url("../../fonts/ptSans/PTSans-Bold.ttf") format("truetype"),
        url("../../fonts/ptSans/PTSans-Bold.svg#PTSans-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PT Sans reg";
    src: url("../../fonts/ptSans/PTSans-Regular.eot");
    src: url("../../fonts/ptSans/PTSans-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ptSans/PTSans-Regular.woff2") format("woff2"),
        url("../../fonts/ptSans/PTSans-Regular.woff") format("woff"),
        url("../../fonts/ptSans/PTSans-Regular.ttf") format("truetype"),
        url("../../fonts/ptSans/PTSans-Regular.svg#PTSans-Regular")
            format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
// PT Sans font end

@font-face {
    font-family: "Proxima Nova Black";
    src: url("../../fonts/ProximaNova-Black.eot");
    src: url("../../fonts/ProximaNova-Black.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ProximaNova-Black.woff2") format("woff2"),
        url("../../fonts/ProximaNova-Black.woff") format("woff"),
        url("../../fonts/ProximaNova-Black.ttf") format("truetype"),
        url("../../fonts/ProximaNova-Black.svg#ProximaNova-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Bold";
    src: url("../../fonts/ProximaNova-Bold.eot");
    src: url("../../fonts/ProximaNova-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ProximaNova-Bold.woff2") format("woff2"),
        url("../../fonts/ProximaNova-Bold.woff") format("woff"),
        url("../../fonts/ProximaNova-Bold.ttf") format("truetype"),
        url("../../fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../../fonts/ProximaNova-Regular.eot");
    src: url("../../fonts/ProximaNova-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ProximaNova-Regular.woff2") format("woff2"),
        url("../../fonts/ProximaNova-Regular.woff") format("woff"),
        url("../../fonts/ProximaNova-Regular.ttf") format("truetype"),
        url("../../fonts/ProximaNova-Regular.svg#ProximaNova-Regular")
            format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Extrabold";
    src: url("../../fonts/ProximaNova-Extrabld.eot");
    src: url("../../fonts/ProximaNova-Extrabld.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ProximaNova-Extrabld.woff2") format("woff2"),
        url("../../fonts/ProximaNova-Extrabld.woff") format("woff"),
        url("../../fonts/ProximaNova-Extrabld.ttf") format("truetype"),
        url("../../fonts/ProximaNova-Extrabld.svg#ProximaNova-Extrabld")
            format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Th";
    src: url("../../fonts/ProximaNovaT-Thin.eot");
    src: url("../../fonts/ProximaNovaT-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/ProximaNovaT-Thin.woff2") format("woff2"),
        url("../../fonts/ProximaNovaT-Thin.woff") format("woff"),
        url("../../fonts/ProximaNovaT-Thin.ttf") format("truetype"),
        url("../../fonts/ProximaNovaT-Thin.svg#ProximaNovaT-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "Proxima Nova Rg";
}
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
h1 {
    font-size: 30px;
}
h2 {
    font-size: 24px;
}
h3 {
    font-size: 18px;
}
h4 {
    font-size: 16px;
}
h5 {
    font-size: 14px;
}
h6 {
    font-size: 12px;
}

@mixin font_24_700 {
    font-family: "Proxima Nova Bold";
    font-size: 24px;
    font-style: normal;
}

@mixin font_24_400 {
    font-family: "Proxima Nova Rg";
    font-size: 24px;
    font-style: normal;
}

@mixin font_12_400 {
    font-family: "Proxima Nova Rg";
    font-size: 12px;
    font-style: normal;
}

@mixin font_12_700 {
    font-family: "Proxima Nova Bold";
    font-size: 12px;
    font-style: normal;
}

@mixin font_14_400 {
    font-family: "Proxima Nova Rg";
    font-size: 14px;
    font-style: normal;
}

@mixin font_14_700 {
    font-family: "Proxima Nova Bold";
    font-size: 14px;
    font-style: normal;
}

@mixin font_16_700 {
    font-family: "Proxima Nova Bold";
    font-size: 16px;
    font-style: normal;
}

@mixin font_16_400 {
    font-family: "Proxima Nova Rg";
    font-size: 16px;
    font-style: normal;
}

@mixin font_18_400 {
    font-family: "Proxima Nova Rg";
    font-size: 18px;
    font-style: normal;
}

@mixin font_18_700 {
    font-family: "Proxima Nova Bold";
    font-size: 18px;
    font-style: normal;
}

@mixin font_30_700 {
    font-family: "Proxima Nova Bold";
    font-size: 30px;
    font-style: normal;
}

@mixin font_30_400 {
    font-family: "Proxima Nova Rg";
    font-size: 30px;
    font-style: normal;
}

@mixin input {
    border: 1px solid #e2e3e4;
    background-color: transparent;
    padding: 16px;
    height: 48px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: $dark_blue;
    border-radius: 0px;
    &:focus-visible {
        box-shadow: none;
        outline: none;
        border: 1px solid #e2e3e4;
    }
    @media (max-width: 767px) {
        height: 44px;
        font-size: 12px;
    }
}
@mixin select {
    border: 1px solid #e2e3e4;
    background-color: transparent;
    padding: 14px 16px;
    height: 48px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: $dark_blue;
    border-radius: 0px;
    display: block;
    width: 100%;
    background-image: url("../../images/chevron-down_dark.svg");
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 20px 20px;
    // &:focus-visible {
    //     box-shadow: none;
    //     outline: none;
    //     border: 1px solid #e2e3e4;
    // }
    @media (max-width: 767px) {
        height: 44px;
        font-size: 12px;
        padding: 14px 16px;
    }
}
.main_title_lh30 {
    @include font_30_700;
    line-height: 30px;
}

.main_title_lh_normal {
    @include font_30_700;
    line-height: normal;
}

.outline_btn {
    @include font_18_700;
    line-height: 16px;
    color: $red;
    width: 200px;
    height: 48px;
    border-radius: 0;
    svg {
        margin-left: 8px;
        @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            margin-top: 2px;
        }
    }
    @media (max-width: 767px) {
        height: 40px;
        font-size: 16px;
        width: 160px;
    }
}
.darkred_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    width: 200px;
    height: 48px;
    @include font_18_700;
    font-weight: 700;
    color: white;
    line-height: 16px;
    border: 1px solid $red;
    border-radius: 0;
    text-transform: capitalize;
    &:hover {
        background-color: white;
        border: 1px solid $red;
        color: $red;
        svg {
            path {
                stroke: $red;
            }
        }
    }
    svg {
        margin-left: 8px;
        @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            margin-top: 2px;
        }
    }
    @media (max-width: 767px) {
        height: 40px;
        font-size: 16px;
        width: 160px;
    }
}
.transparent_btn {
    border: 1px solid $red;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    padding: 16px;
    height: 48px;
    @include font_18_700;
    font-weight: 700;
    color: $red;
    line-height: 16px;
    color: $red;
    border-radius: 0;
    &:hover {
        background-color: $red;
        border: 1px solid $red;
        color: $white;
        svg {
            path {
                stroke: $white;
            }
        }
    }
    svg {
        margin-left: 10px;
        path {
            stroke: $red;
        }
        @media (max-width: 767px) {
            width: 20px;
            height: 20px;
            margin-top: 2px;
        }
    }
    @media (max-width: 767px) {
        height: 40px;
        font-size: 16px;
        width: auto;
        padding: 8px;
    }
}
.left_button {
    svg {
        margin-left: 0px;
        margin-right: 10px;
        @media (max-width: 767px) {
            margin-right: 8px;
        }
    }
}
.spread_btns {
    justify-content: space-between !important;
}
.container {
    max-width: 1520px;
    margin: 0 auto;
    padding: 0px 40px;
    @media (max-width: 767px) {
        padding: 0px 16px;
    }
}

// form field
.form-row {
    margin-bottom: 20px;
    @media (max-width: 767px) {
        margin-bottom: 24px;
    }
    label {
        @include font_14_400;
        color: $shadeGrey;
        text-transform: capitalize;
        margin-bottom: 8px;
        display: inline-block;
        line-height: normal;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    input {
        @include input;
    }
    select {
        @include select;
    }
}

.combine_fields {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    .form-row {
        &:first-child {
            width: 100%;
        }
        input {
            background-color: $white;
            width: 100%;
            border-right: 0px;
        }
        select {
            background-color: $white;
            width: 112px;
            @media (max-width: 767px) {
                width: 91px;
            }
        }
    }
}
.combine_field_checkbox {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        // gap: 12px;
        margin-bottom: 12px;
    }
    .checkbox_inner_field {
        width: 100%;
        max-width: 240px;
        margin-right: 20px;
        @media (min-width: 992px) and (max-width: 1349px) {
            max-width: 50%;
            margin-right: 10px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            max-width: 54%;
            margin-right: 10px;
        }
        .mobile_certification_select {
            display: none;
        }
        @media (max-width: 767px) {
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            width: 100%;
            max-width: 100%;
            margin-bottom: 12px;
            .form-row {
                width: 100%;
                input {
                    width: 100%;
                }
            }
            .mobile_certification_select {
                display: block;
                .form-row select {
                    width: 91px;
                }
            }
        }
    }
}
// }
.dashboard_page_main {
    position: relative;
}
.bottom_action_btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: static;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: 20px 0px;
    .darkred_btn {
        margin-left: auto;
        @media (max-width: 991px) {
            margin-left: 0px;
        }
    }
    @media (max-width: 991px) {
        margin-bottom: 43px;
    }
    @media (max-width: 767px) {
        padding: 12px 0px;
    }
}
.mb_md_20 {
    @media (max-width: 767px) {
        margin-bottom: 20px;
    }
}
.px_10 {
    padding-left: 10px;
    padding-right: 10px;
}
.mb_20 {
    margin-bottom: 20px;
}
.mb_24 {
    margin-bottom: 24px;
}
.mb_md_12 {
    margin-bottom: 12px;
    @media (max-width: 991px) {
        margin-bottom: 0px;
    }
}
.full_hr {
    border-top: 1px solid #e2e3e4;
    width: 100%;
    position: absolute;
    left: 0px;
    margin-top: -90px;
    @media (max-width: 767px) {
        margin-top: -70px;
    }
}
.full_hr_iPad_bottom {
    @media (max-width: 991px) {
        width: 100%;
        position: absolute;
        left: 0px;
        margin-top: 90px;
        border-bottom: 1px solid #e2e3e4;
        @media (max-width: 767px) {
            margin-top: 70px;
        }
    }
}
.w_max_325 {
    max-width: 325px;
    @media (max-width: 991px) {
        max-width: 100% !important;
    }
}
.w_991_full {
    @media (max-width: 991px) {
        width: 100% !important;
    }
}
.dashboard {
    display: block;
    position: relative;
    min-height: 100vh;
}

// scroll bar
div::-webkit-scrollbar-track {
    border-radius: 4px;
}
div::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px #808285;
    background-color: #808285;
}

// table scrollbar
.p-datatable-wrapper {
    padding-bottom: 4px;
}
.p-datatable-wrapper::-webkit-scrollbar-track {
    border-radius: 4px;
    margin-top: 10px;
}
.p-datatable-wrapper::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.p-datatable-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px #808285;
    background-color: #808285;
}

.table_heading_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    @media (max-width: 767px) {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
    }
    h1 {
        margin-bottom: 0px;
    }
}

.breadcrumb {
    margin-bottom: 12px;
    ul {
        padding-left: 0px;
        margin-bottom: 0px;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        li {
            .crumb_link {
                @include font_14_400;
                line-height: normal;
                color: $shadeGrey;
                text-decoration: none;
                font-weight: 400;
                text-transform: capitalize;
                &::after {
                    content: "/";
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
            &:last-child {
                .crumb_link {
                    @include font_14_700;
                    color: $dark_blue;
                    font-weight: 700;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.details_head_block {
    margin: 32px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
        margin: 24px 0px;
        flex-direction: column;
        align-items: flex-start;
    }
}
.details_heading_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // gap: 16px;
    // @media (max-width: 767px) {
    //     gap: 8px;
    // }
    .heading_details {
        @include font_30_700;
        line-height: 30px;
        font-weight: 700;
        margin-bottom: 0px;
        margin-left: 16px;
        @media (max-width: 767px) {
            font-size: 24px;
            line-height: 30px;
            margin-left: 8px;
        }
    }
    .back_svg {
        @media (max-width: 767px) {
            width: 24px;
            height: 24px;
        }
        img {
            @media (max-width: 767px) {
                width: 24px;
                height: 24px;
                margin-top: -6px;
            }
        }
    }
}
.details_block {
    padding: 36px 36px 36px 36px;
    border: 1px solid #e2e3e4;
    background: $white;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        padding: 24px 16px 24px 16px;
    }
    @media (min-width: 768px) {
        &:first-child {
            padding: 36px 25px 36px 36px;
        }
    }
    .inner_details {
        border-bottom: 1px solid #e2e3e4;
        padding-bottom: 24px;
        &:last-child {
            border-bottom: 0px;
            padding-bottom: 0px;
        }
    }
    .form-row {
        margin-bottom: 0px;
        margin-top: 32px;
        @media (max-width: 767px) {
            margin-top: 24px;
        }
        label {
            margin-bottom: 12px;
            color: $dark_grey;
        }
    }
    .details_data {
        @include font_24_400;
        font-weight: 400;
        line-height: normal;
        color: $black;
        margin-bottom: 0px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 1366px) {
            width: 98%;
        }
        @media (max-width: 1200px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
}
.align_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.order_open {
    @include font_12_700;
    line-height: normal;
    height: 30px;
    vertical-align: middle;
    background-color: #fee1c8;
    padding: 8px 13px;
    border-radius: 40px;
    color: #f57e20;
    @media (max-width: 767px) {
        font-size: 10px;
        padding: 9px 13px;
    }
}
.right_align_box {
    display: flex;
    .form-row {
        margin-left: auto;
    }
}
.iPad_col_100 {
    @media (max-width: 1200px) {
        width: 100%;
        .form-row {
            margin-left: 0px;
        }
    }
}
.ipad_col_50_100 {
    @media (max-width: 1200px) {
        width: 50%;
        .form-row {
            margin-left: 0px;
        }
    }
    @media (max-width: 991px) {
        width: 100%;
    }
}
.ipad_col_50 {
    @media (max-width: 1200px) {
        width: 50%;
        .form-row {
            margin-left: 0px;
        }
    }
}
.w-max-991_col_50 {
    @media (max-width: 991px) {
        width: 50%;
    }
}
.sub_details_head {
    @include font_18_700;
    font-weight: 700;
    line-height: 18px;
    color: $dark_blue;
    margin-bottom: -8px;
    @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 0px;
    }
}

.result_found_wrapper {
    position: absolute;
    width: 100%;
    max-width: 320px;
    z-index: 99;
    top: 12px;
    left: 50%;
    transform: translate(-50%, 0px);
    &.ToastMsgAdd {
        .result_found {
            background-color: $orange !important;
            border: 1px solid $orange !important;
            .result_found_text {
                color: $black !important;
            }
            button {
                svg {
                    path {
                        stroke: $black !important;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        top: 15px;
    }
    .result_found {
        border: 1px solid $green;
        padding: 12px 10px 12px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $light_green;
        border-radius: 4px;
        // height: 48px;
        @media (max-width: 767px) {
            // height: 44px;
        }
        .result_found_text {
            margin: 0;
            font-size: 14px;
            line-height: 17.05px;
            color: $dark_blue;
            font-weight: 700;
            padding-right: 5px;
        }
        button {
            border: none;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.hide {
            display: none;
        }
    }
    &.error-toaster {
        .result_found {
            background-color: $dark_red;
            border: 1px solid #e80f17;
            .result_found_text {
                color: $white;
            }
            button {
                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }
}
.search_result_head_section {
    padding-top: 80px;
    @media (max-width: 767px) {
        padding-top: 24px;
    }
}
section {
    .table_head {
        // @media (max-width: 767px) {
        //     padding-right: 0;
        //     min-width: 100%;
        // }
        .table_head_wrapper {
            padding: 35px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            overflow: hidden;
            position: relative;
            // background: linear-gradient(180deg, #ffeaec 0%, rgba(255, 217, 221, 0) 100%);
            background-image: url("../../images/table_nav_img-without-logo.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
            height: 128px;
            @media (max-width: 991px) {
                background-size: cover;
            }
            @media (max-width: 767px) {
                padding: 16px;
                height: 88px;
            }
            .table_header_bg {
                position: absolute;
                right: 52px;
                top: 50%;
                transform: translate(30px, -50%);
                @media (max-width: 1100px) {
                    right: 20px;
                }
                img {
                    width: 175px;
                    height: auto;
                    @media (max-width: 1100px) {
                        width: 135px;
                    }
                    @media (max-width: 767px) {
                        width: 80px;
                    }
                }
            }
            .table_head_left {
                display: flex;
                .arrow_left_img {
                    margin-right: 18px;
                    @media (max-width: 767px) {
                        height: 24px;
                        width: 24px;
                        margin-right: 8px;
                    }
                }
                .table_head_text {
                    .table_head_heading {
                        font-size: 30px;
                        line-height: 100%;
                        font-weight: 700;
                        color: $dark_blue;
                        margin-bottom: 12px;
                        @media (max-width: 767px) {
                            font-size: 24px;
                            line-height: 30px;
                        }
                    }
                    .table_head_title {
                        font-size: 16px;
                        line-height: 100%;
                        color: $dark_grey;
                        margin: 0;
                        @media (max-width: 767px) {
                            line-height: 14px;
                        }
                    }
                }
            }
            .table_head_right {
                position: absolute;
                right: 24px;
                bottom: 16px;
                max-width: 130px;
                display: none;
                @media (max-width: 767px) {
                    max-width: 66px;
                    height: auto;
                    right: 0px;
                }
                img {
                    max-width: 100%;
                    max-height: 170px;
                }
            }
        }
    }
}

.table_criteria {
    @media (max-width: 767px) {
        overflow: auto;
    }
    .table_criteria_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $dark_red;
        padding: 15px 15px 16px 20px;
        min-width: 1160px;
        @media (max-width: 767px) {
            padding: 0px;
            overflow: unset;
        }
        .table_criteria_block {
            max-width: fit-content;
            width: 100%;
            min-width: 70px;
            margin-right: auto;
            position: relative;
            @media (max-width: 767px) {
                padding: 14px 0px 14px 16px;
                &::after {
                    display: none;
                }
            }
            &:last-child {
                border: none;
                &::after {
                    display: none;
                }
            }
            &::after {
                content: "";
                position: absolute;
                right: -32px;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: $white;
                @media (max-width: 1280px) {
                    right: -16px;
                }
                @media (max-width: 767px) {
                    height: 69%;
                    top: 9px;
                }
            }
            .table_criteria_text {
                font-size: 16px;
                line-height: 19.49px;
                font-weight: 700;
                color: $white;
                margin: 0;
            }
            .table_criteria_uppertext {
                font-size: 14px;
                line-height: 17.05px;
                font-weight: 400;
                color: $white;
                margin-bottom: 4px;
            }
            .table_criteria_lowertext {
                font-size: 16px;
                line-height: 100%;
                color: $white;
                font-weight: 400;
                margin-bottom: 0;
            }
        }
    }
}
.sm-px-5 {
    @media (max-width: 767px) {
        &:nth-child(even) {
            padding-left: 3px;
            padding-right: 2px;
            width: 45.5%;
        }
        &:nth-child(odd) {
            padding-right: 0px;
            width: 54.5%;
        }
    }
}
input[type="radio"] {
    height: 20px;
    width: 20px;
    accent-color: $red;
}
.table_checkbox {
    .p-radiobutton {
        .p-radiobutton-box {
            border: 2px solid $red;
            background-color: #fff;
            &.p-focus {
                box-shadow: none;
            }
        }
        .p-radiobutton-icon {
            background-color: $red;
        }
        &.p-highlight:not(.p-disabled) {
            &:hover {
                background-color: #fff;
            }
        }
    }
}
.send_enquiry_head {
    .send_enquiry_row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        .enquiries_img_box {
            padding: 11px 8px;
            border: 1px solid #e2e3e4;
            img {
                width: 83px;
                height: auto;
            }
        }
        .enq_data_main {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 40px;
            @media (max-width: 767px) {
                flex-direction: column;
                gap: 0px;
                align-items: flex-start;
            }
        }
        .enq_data_block {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;
        }
        .enq_label {
            margin-top: 16px;
            @include font_14_400;
            color: $dark_grey;
            line-height: normal;
            @media (max-width: 767px) {
                margin-top: 8px;
            }
        }
        .enq_data {
            margin-top: 16px;
            @include font_16_400;
            color: $black;
            line-height: 16px;
            @media (max-width: 767px) {
                margin-top: 8px;
            }
        }
    }
}
.popup_pump_heading_block {
    padding: 32px 32px 0px 32px;
    @media (max-width: 767px) {
        padding: 24px 16px 0px 16px;
    }
}
.send_enquiry_heading {
    @include font_18_700;
    line-height: 18px;
    font-weight: 700;
    color: $dark_blue;
    margin-bottom: 0px;
}
.addNewBtnShow {
    display: block !important;
}
// toogle button
.modificationShow {
    display: flex !important;
}
.modification_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    @media (max-width: 767px) {
        margin-top: 20px;
    }
}

.visible_toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    span {
        @include font_18_400;
        color: $dark_blue;
        font-weight: 400;
        line-height: normal;
    }
}
.new_pump_toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: 767px) {
        gap: 0px;
    }
}
.toggle_checkbox {
    position: relative;
    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
    input[type="checkbox"] {
        &:checked + label:before {
            background: #0bda6c;
        }
    }
    input[type="checkbox"] {
        &:checked + label:after {
            left: 27px;
        }
    }
    label {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        &::before {
            content: "";
            width: 49px;
            height: 24px;
            background: #e2e3e4;
            position: relative;
            display: inline-block;
            border-radius: 46px;
            box-sizing: border-box;
            transition: 0.2s ease-in;
        }
        &::after {
            content: "";
            position: absolute;
            width: 19px;
            height: 19px;
            border-radius: 50%;
            left: 2.94px;
            top: 2.4px;
            z-index: 2;
            background: #fff;
            box-sizing: border-box;
            transition: 0.2s ease-in;
        }
    }
}

.edit_deleted_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 39px;
    @media (max-width: 767px) {
        gap: 20px;
    }
    a {
        @include font_18_400;
        line-height: normal;
        color: $red;
        text-decoration: none;
    }
}
.edit_box,
.delete_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}
.checkbox_main {
    @media (max-width: 991px) {
        margin-top: -6px;
    }
}
.checkbox_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    height: 48px;
    @media (max-width: 767px) {
        height: 24px;
        gap: 10px;
    }
    .checkbox_block {
        margin-bottom: 0px;
        label {
            margin-bottom: 0px;
        }
        input[type="checkbox"] {
            &:checked + label:after {
                background-size: 20px 20px;
                @media (max-width: 767px) {
                    background-size: 20px 20px;
                }
            }
        }
        label {
            position: relative;
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            @media (max-width: 1399px) {
                width: 20px;
                height: 20px;
            }
            @media (max-width: 767px) {
                width: 20px;
                height: 20px;
            }
            &::after {
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                @media (max-width: 1399px) {
                    background-size: 20px 20px;
                    width: 20px;
                    height: 20px;
                }
                @media (max-width: 767px) {
                    background-size: 20px 20px;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        span {
            @media (min-width: 768px) and (max-width: 1399px) {
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
}
.checkbox_block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // gap: 8px;
    margin-bottom: 13px;
    position: relative;
    @media (max-width: 767px) {
        margin-bottom: 0px;
    }
    input[type="checkbox"] {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
    input[type="checkbox"] {
        &:checked + label:after {
            background-image: url("../../images/Chechbox.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            @media (max-width: 767px) {
                background-size: 16px 16px;
            }
        }
    }
    label {
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @media (max-width: 1399px) {
            width: 20px;
            height: 20px;
        }
        @media (max-width: 767px) {
            width: 16px;
            height: 16px;
        }
        &::after {
            content: "";
            position: absolute;
            width: 24px;
            height: 24px;
            right: 0px;
            z-index: 2;
            box-sizing: border-box;
            background-image: url("../../images/CheckedNo.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 24px 24px;
            @media (max-width: 1399px) {
                background-size: 20px 20px;
                width: 20px;
                height: 20px;
            }
            @media (max-width: 767px) {
                background-size: 16px 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
    span {
        @include font_16_400;
        line-height: 16px;
        color: $dark_blue;
        margin-bottom: 0px;
        @media (min-width: 768px) and (max-width: 1399px) {
            font-size: 14px;
        }
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
}
.search_field_block {
    position: relative;
    margin-right: 16px;
    @media (max-width: 767px) {
        margin-right: 8px;
    }
    .form-row {
        margin-bottom: 0px;
    }
    .search_svg {
        position: absolute;
        left: 16px;
        top: 10px;
        height: auto;
        width: 16px;
        @media (max-width: 767px) {
            top: 7px;
        }
    }
    .search_field {
        padding-left: 42px;
        @include font_16_700;
        font-weight: 700;
        color: $dark_grey;
        width: 332px;
        max-width: 322px;
        @media (max-width: 767px) {
            width: 100%;
            max-width: 100%;
        }
        &::placeholder {
            @include font_16_700;
            font-weight: 700;
            color: $dark_grey;
        }
    }
}
.uploaded_pdf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 333px;
    padding: 16px;
    background-color: #f3f4f4;
    .pdf_left {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pdf_details {
            p {
                @include font_14_400;
                color: $shadeGrey;
                line-height: normal;
                margin-bottom: 2px;
            }
            a {
                @include font_18_400;
                color: $dark_blue;
                line-height: normal;
                margin-bottom: 0px;
                text-decoration: none;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 200px;
                overflow: hidden;
                width: 100%;
                display: block;
                pointer-events: none;
            }
        }
    }
    .pdf_right_icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }
}
.filterOpen {
    .overlay {
        width: 100%;
        position: absolute;
        background: #00000075;
        right: 0px;
        height: 200vh;
        top: -90px;
        z-index: 9;
        pointer-events: none;
    }
}
.overflow_hidden {
    overflow: hidden;
}
.pumps_tabs {
    .p-tabview {
        .p-tabview-nav {
            background: transparent;
            border: none;
            border-width: 0;
            padding-left: 0px;
            margin-bottom: 32px;
            padding: 0px;
            list-style-type: none;
            list-style: none;
            @media (max-width: 767px) {
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .p-unselectable-text {
                .p-tabview-nav-link {
                    text-decoration: none;
                    border: none;
                    border-radius: 0px;
                    span {
                        @include font_18_400;
                        color: $black;
                        font-weight: 400;
                        line-height: 28px;
                        letter-spacing: 0.18px;
                        padding: 5px 24px;
                        border: 1px solid #5d5d70;
                        border-radius: 0px;
                        border-right: 0px;
                        @media (max-width: 767px) {
                            font-size: 16px;
                            letter-spacing: 0.16px;
                        }
                    }
                }
                &.p-highlight {
                    background-color: #f57e20;
                    display: inline-block;
                    overflow: hidden;
                    .p-tabview-nav-link {
                        span {
                            border: 1px solid #f57e20;
                        }
                    }
                }
                &:nth-last-child(2) {
                    span {
                        border-right: 1px solid #5d5d70;
                    }
                }
            }
        }
    }
}
.custom_unit {
    @include font_14_400;
    line-height: normal;
    color: $shadeGrey;
    @media (max-width: 767px) {
        font-size: 12px;
    }
}
.md-none {
    @media (max-width: 767px) {
        display: none;
    }
}
.pdf_icons_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    position: absolute;
    right: 50px;
    top: 24px;
    @media (max-width: 767px) {
        right: 20px;
        top: 10px;
        gap: 8px;
    }
}
.pdf_icons {
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid #e2e3e4;
    filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.07));
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 45px;
    transition: 0.5s;
    @media (max-width: 767px) {
        height: 30px;
        width: 30px;
        svg {
            width: 16px;
            height: 16px;
        }
    }
    &:hover {
        transform: scale(1.1);
    }
}

.stockAvailability_two_col {
    &.custom_dataTable {
        .p-datatable-wrapper {
            table {
                thead {
                    tr {
                        th {
                            width: 50%;
                            &:last-child {
                                width: 50% !important;
                                .p-column-header-content {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            width: 50%;
                            &:last-child {
                                width: 50% !important;
                                text-align: left !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.popUpStockAvailability_two_col {
    &.custom_dataTable {
        .p-datatable-wrapper {
            table {
                thead {
                    tr {
                        th {
                            width: 65%;
                            &:last-child {
                                width: 35% !important;
                                .p-column-header-content {
                                    justify-content: flex-start;
                                    padding-left: 20px;
                                }
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            width: 65%;
                            &:last-child {
                                width: 35% !important;
                                text-align: left !important;
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.min-768_max-991_wid {
    @media (min-width: 768px) and (max-width: 991px) {
        width: 50% !important;
    }
}
.selected_pump_shows {
    border: 1px solid $red;
    background: $white;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 83px;
    @media (max-width: 767px) {
        height: 64px;
        padding: 9px 12px 9px 16px;
    }
    .selected_pump_img_name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        @media (min-width: 767px) and (max-width: 991px) {
            gap: 20px !important;
        }
        // img {
        //     max-width: 40px;
        //     width: 100%;
        //     height: auto;
        //     max-height: 50px;
        //     margin-right: 16px;
        //     @media (max-width: 1199px) {
        //         margin-right: 8px;
        //     }
        //     @media (max-width: 767px) {
        //         max-width: 36px;
        //     }
        // }

        .pump_field_img {
            @media (max-width: 991px) {
                min-width: 60px;
            }
            @media (max-width: 767px) {
                min-width: 40px;
            }
        }

        .vt_pump_img {
            @media (min-width: 768px) {
                max-width: 45px;
                min-width: 45px;
                min-height: 81px;
            }
            @media (min-width: 768px) and (max-width: 991px) {
                margin:  0 auto;
            }
            @media (max-width: 767px) {
                min-width: 32px;
                min-height: 60px;
                max-width: max-content;
                display: block;
            }
        }
        img {
            // max-width: 54px;
            max-width: 56px;
            max-height: 64px;

            @media (max-width:767px) {
                max-width: 42px;
                max-height: 50px;
            }
        }
        .pump_name_show {
            @include font_18_400;
            font-weight: 400;
            line-height: normal;
            color: $dark_blue;
            white-space: nowrap;
            @media (min-width: 768px) and (max-width: 1199px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
    .change_type {
        a {
            @include font_16_400;
            font-weight: 400;
            line-height: normal;
            color: $red;
            text-decoration: none;
            @media (min-width: 768px) and (max-width: 1199px) {
                font-size: 14px;
            }
            @media (max-width: 767px) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}
.form-row {
    .iPad_label_d-none {
        @media (max-width: 991px) {
            display: none;
        }
    }
}
.fileTypeField {
    border: 1.5px dashed #808285;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 92px;
    padding: 20px;
    @media (max-width: 767px) {
        padding: 9px 12px 9px 16px;
        height: auto;
    }
    p,
    label {
        margin-bottom: 0px;
    }
    .input_file_container {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
        }
        .instruction_show,
        .customFileInput {
            @include font_14_700;
            font-weight: 700;
            line-height: normal;
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
        .browse_text {
            color: $orange;
            cursor: pointer;
            font-weight: 700;
        }
    }
    .pdf_file_uploaded {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
        }
        .pdf_details_show {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            .pdf_name_size {
                .file_name {
                    @include font_18_700;
                    font-weight: 700;
                    color: $dark_blue;
                    line-height: 18px;
                    @media (max-width: 767px) {
                        font-size: 16px;
                    }
                }
                .file_size {
                    @include font_14_400;
                    font-weight: 400;
                    color: $shadeGrey;
                    line-height: normal;
                    margin-top: 8px;
                    @media (max-width: 767px) {
                        font-size: 12px;
                    }
                }
            }
        }
        .delete_and_change {
            .uploaded_pdf_icon {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                @media (max-width: 767px) {
                    gap: 8px;
                    padding-left: 10px;
                }
                .icons_block {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                    @media (max-width: 767px) {
                        gap: 4px;
                    }
                    .icon_svg {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                            @media (max-width: 767px) {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                    .icon_text {
                        @include font_16_400;
                        font-weight: 400;
                        color: $gray;
                        line-height: 16px;
                        text-decoration: none;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
.field_wrapper {
    position: relative;
    .p-error {
        position: absolute;
        bottom: 0px;
        white-space: nowrap;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
}
.card {
    border: none;
}
.p-paginator-first {
    display: none;
}
.p-paginator-last {
    display: none;
}
.p-paginator-prev {
    border: none;
    background-color: #00000000;
    margin-right: 15px;
    @media (max-width: 767px) {
        margin-right: 5px;
    }
}
.p-paginator-next {
    margin-left: 15px;
    border: none;
    background-color: #00000000;
    @media (max-width: 767px) {
        margin-left: 5px;
    }
}
.p-paginator {
    margin-top: 24px;
    border-bottom: 0px;
    padding-bottom: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    button {
        width: 30px;
        min-width: 30px;
        height: 30px;
        border: none;
        @media (max-width: 767px) {
            width: 25px;
            min-width: 25px;
            height: 25px;
        }
        &:hover {
            background-color: #e2e3e4;
            border-radius: 6px;
        }
        &:focus-visible {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }
    button {
        &:disabled {
            &:hover {
                background-color: #00000000;
            }
            svg {
                path {
                    fill: #81878d;
                }
            }
        }
        svg {
            @media (max-width: 767px) {
                width: 15px;
                height: 15px;
            }
            path {
                stroke-width: 1.5px;
                fill: $red;
            }
        }
    }
}
.p-paginator-pages {
    .p-paginator-page {
        font-family: "PT Sans bold";
        margin: 0px 10px;
        min-width: 30px;
        width: 30px;
        height: 30px;
        font-size: 14px;
        line-height: normal;
        font-weight: 700;
        color: #81878d;
        border-radius: 6px;
        border: none;
        @media (max-width: 767px) {
            font-size: 12px;
            width: 25px;
            min-width: 25px;
            height: 25px;
            margin: 0px 5px;
        }
        &:hover {
            background-color: $red;
            color: $white;
            border: none;
        }
        &.p-highlight {
            background-color: $red;
            color: $white;
            border: none;
        }
    }
}
.form-control:disabled {
    background-color: transparent;
}
.status_shows {
    border: 1px solid #e2e3e4;
    width: fit-content;
    height: 28px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
    white-space: nowrap;
}
.form-row {
    input[type="textarea"] {
        height: 100px;
        width: 100%;
        min-width: 100%;
    }
    .msg_textarea {
        height: 100px;
        width: 100%;
        min-width: 100%;
        word-break: break-word;
        border: 1px solid #e2e3e4;
        background-color: transparent;
        padding: 16px;
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        color: $dark_blue;
        border-radius: 0px;
        &:focus-visible {
            box-shadow: none;
            outline: none;
            border: 1px solid #e2e3e4;
        }
        @media (max-width: 767px) {
            height: 88px;
            font-size: 12px;
        }
    }
}
.min_100 {
    min-width: 100%;
}
.add_new {
    @media (max-width: 767px) {
        margin-top: 24px;
    }
}
.required_field {
    .form-row {
        label {
            position: relative;
            &::after {
                content: "*";
                position: absolute;
                top: 1px;
                right: -7px;
                font-size: 12px;
                color: $dark_red;
            }
        }
    }
}
.prime_dropdown {
    .form-row {
        .p-dropdown {
            &.p-component {
                display: flex;
                height: 100%;
                min-height: 48px;
                width: 100% !important;
                border: 1px solid #e2e3e4;
                width: 100% !important;
                border-radius: 0px;
                padding: 16px 0px 16px 16px;
                @include font_16_400;
                color: $dark_blue;
                line-height: 16px;
                @media (max-width: 767px) {
                    font-size: 12px;
                    height: 44px;
                }
                &:focus-visible {
                    box-shadow: none;
                    outline: none;
                    border: 1px solid #e2e3e4;
                }
                option {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    @include font_16_400;
                    color: $dark_blue;
                    padding: 16px 0px !important;
                    width: 100%;
                }
                input {
                    height: 100%;
                    min-height: 48px;
                    padding: 16px 0px !important;
                    &::placeholder {
                        padding: 16px 0px;
                    }
                    @media (max-width: 767px) {
                        font-size: 12px;
                        height: 44px;
                    }
                }
                span {
                    .flex {
                        height: 100%;
                        div {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            @include font_16_400;
                            color: $dark_blue;
                            line-height: 16px;
                            @media (max-width: 767px) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
            .p-dropdown-trigger {
                background-image: url("../../images/chevron-down_dark.svg");
                transition: border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                background-repeat: no-repeat;
                background-position: right 0.75rem center;
                background-size: 20px 20px;
                svg {
                    display: none;
                }
            }
        }
    }
}
.booking_country{
    .p-component{
        height: 48px !important;
        padding-top: 14px !important;
        padding-bottom: 14px !important;
        .p-dropdown-label{
            span{
                font-family: "Proxima Nova Rg";
                color: $dark_blue;
            }
        }
        @media (max-width: 767px) {
            height: 44px !important;
            min-height: 44px !important;
            padding-top: 12px !important;
            padding-bottom: 12px !important;
                .p-dropdown-label{
                    span{
                        font-size: 12px;
                    }
                }
        }
    }
    @media (max-width: 767px) {
        &.new_label {
            label{
                margin-bottom: 8.61px;
            }
        }
    }
}
.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
}
.p-dropdown-panel {
    z-index: 1058 !important;
    .p-dropdown-header {
        .p-dropdown-filter-container {
            input {
                border: 1px solid #e2e3e4;
                background-color: transparent;
                padding: 16px 40px 16px 16px;
                @include font_16_400;
                line-height: 16px;
                color: $dark_blue;
                border-radius: 0px;
                &:focus-visible {
                    box-shadow: none;
                    outline: none;
                    border: 1px solid #e2e3e4;
                }
                @media (max-width: 767px) {
                    height: 44px;
                    font-size: 12px;
                }
            }
        }
        .p-dropdown-filter-icon {
            top: 17px;
            right: 20px;
        }
    }
    .p-dropdown-empty-message {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0px;
        color: $dark_blue;
    }
}
.contact_select_dropdown {
    &.p-dropdown-panel {
        max-width: 128px;
    }
}
.filter_country_select_dropdown {
    &.p-dropdown-panel {
        @media (max-width: 767px) {
            max-width: 280px !important;
            left: unset !important;
            right: 20px !important;
        }
    }
}
.country_select_dropdown {
    &.p-dropdown-panel {
        max-width: 321px;
    }
}
.phone_code {
    &.prime_dropdown {
        .form-row {
            width: 60%;
            label {
                left: -65%;
                @media (max-width: 767px) {
                    left: -66%;
                }
            }
            input {
                border-right: 1px solid #e2e3e4;
            }
            &:first-child {
                width: 40%;
                label {
                    display: none;
                }
            }
            .p-dropdown {
                &.p-component {
                    height: 48px;
                    border-right: 0px;
                    @media (max-width: 767px) {
                        height: 44px;
                        padding: 14px 0px 14px 16px;
                        min-height: 44px;
                    }
                    .p-dropdown-label {
                        @include font_12_400;
                        line-height: 16px;
                        color: $dark_blue;
                        @media (max-width: 767px) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.request {
    display: inline-flex;
    height: 28px;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    background: #a4f4ff;
    border: none;
    color: #178ccd;
    @include font_12_700;
    @media (max-width: 767px) {
        display: flex;
    }
}
.in_progress {
    display: inline-flex;
    height: 28px;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 40px;
    background: #ccfec8;
    border: none;
    color: #45ab43;
    @include font_12_700;
}
.link_text {
    text-decoration: none;
    @include font_14_700;
    color: $white;
    margin-right: 20px;
    text-transform: capitalize;
    background-color: $red;
    padding: 12px 14px;
    border: 1px solid $red;
    &:hover {
        color: $dark_red;
        background-color: transparent;
    }
    @media (max-width: 767px) {
        margin-top: 16px;
        padding: 10.5px 12px;
    }
}
.p-multiselect-items-wrapper {
    .p-multiselect-items {
        padding: 16px;
        margin-bottom: 0px;
        .p-multiselect-item {
            color: $dark_blue;
            background: transparent;
            @include font_16_400;
            &:focus {
                background: transparent;
                box-shadow: none;
            }
            &:focus-visible {
                background: transparent;
                box-shadow: none;
            }
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .p-checkbox {
        margin-right: 10px;
        &:not(.p-checkbox-disabled) {
            background: transparent;
            border-color: transparent;
        }
        .p-checkbox-box {
            border-color: $red;
            border-radius: 0px;
            &.p-highlight {
                background-color: $red;
                border-color: $red;
            }
        }
    }
    .p-multiselect-item.p-highlight {
        color: $dark_blue;
        background: transparent;
        @include font_16_400;
    }
}
.p-checkbox {
    .p-checkbox-box {
        border-color: $red;
        border-radius: 0px;
        &.p-highlight {
            background-color: $red;
            border-color: $red;
        }
        &.p-focus {
            box-shadow: none;
        }
    }
    .p-checkbox-box.p-highlight {
        background-color: $red;
        border-color: $red;
        border-radius: 0px;
    }
}
.p-multiselect-panel {
    border-radius: 0px;
    .p-multiselect-header {
        padding: 5px 5px 5px 16px;
    }
}
.p-multiselect {
    &.p-multiselect-chip {
        .p-multiselect-token {
            background-color: transparent;
            .p-multiselect-token-label {
                color: $dark_blue;
                @include font_16_400;
                margin-right: 5px;
            }
            svg {
                margin-right: 5px;
            }
        }
    }
}

.new_label {
    label {
        @include font_14_400;
        color: $shadeGrey;
        text-transform: capitalize;
        margin-bottom: 8px;
        display: inline-block;
        line-height: normal;
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    .form-row {
        label {
            display: none;
        }
    }
}
.p-multiselect-header {
    .p-multiselect-select-all {
        &::after {
            content: "Select All";
            position: absolute;
            color: $dark_blue;
            @include font_16_400;
            margin-left: 10px;
            margin-top: 2px;
        }
    }
}
.textarea_main {
    position: relative;
    .letter_count {
        position: absolute;
        right: 10px;
        bottom: 28px;
        background-color: $white;
        @include font_14_400;
        color: $shadeGrey;
        @media (max-width: 767px) {
            font-size: 12px;
            bottom: 35px;
        }
    }
}
.ag-chart-wrapper {
    touch-action: auto !important;
}
.p-menu-list {
    list-style: none;
    list-style-type: none;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
}
// .loader_view{
//     overflow: hidden;
// }
.loader_view_listing {
    overflow: hidden;
    .modal {
        &.show {
            &.graph_modal {
                .modal-dialog {
                    .modal-content {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.show_loader {
    position: absolute;
    z-index: 999999;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.9;
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.show_loader_listing {
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.9;
    img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.enquiry_img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 90%;
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
        max-width: 85%;
    }
}
.filter_modal_body {
    .p-dropdown-label {
        span {
            @include font_16_400;
            line-height: 16px;
            color: $dark_blue;
        }
        div {
            div {
                @include font_16_400;
                line-height: 16px;
                color: $dark_blue;
            }
        }
    }
}
@media (max-width: 767px) {
    .p-dropdown-panel
        .p-dropdown-items-wrapper
        .p-dropdown-items
        .p-dropdown-item
        div
        div {
        max-width: 240px;
        text-wrap: wrap;
    }
}
.graph_modal {
    .operator_graph {
        .inner_details {
            .pump_img {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: center;
                height: 100%;
                .pumpImg {
                    max-width: 133px;
                }
            }
        }
    }
}
.toaster_popup {
    background-color: #d1fadf;
    border: 1px solid #e2e3e4;
    box-shadow: 0px 0px 24px 0px #00000017;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    max-width: 317px;
    position: absolute;
    right: -10px;
    top: 10px;
    z-index: 99;
    @media (max-width: 767px) {
        top: 12px;
        right: -6px;
    }
    h5 {
        display: block;
        margin-bottom: 4px;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.92px;
        text-align: left;
        font-family: "Proxima Nova Bold";
        color: $dark_blue;
    }
    p {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #868594;
    }
}
.modal {
    &.show {
        &.login_popup_modal {
            padding-right: 0px !important;
            .modal-dialog {
                max-width: 468px;
                .modal-content {
                    .modal-header {
                        border-bottom: none !important;
                        @media (max-width: 767px) {
                            padding: 24px 16px 24px 16px;
                        }
                        h2 {
                            font-family: "Proxima Nova Bold";
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 24.36px;
                            text-align: left;
                        }
                    }
                    .modal-body {
                        padding-top: 0px;
                        padding-bottom: 13px;
                        padding-left: 32px;
                        padding-right: 32px;
                        @media (max-width: 767px) {
                            padding-left: 16px;
                            padding-right: 16px;
                            padding-bottom: 32px;
                        }
                        label {
                            line-height: 17.05px;
                        }
                        .mb__0 {
                            .form-row {
                                margin-bottom: 0px;
                            }
                        }
                    }
                    .modal-footer {
                        @media (max-width: 767px) {
                            padding-bottom: 24px;
                        }
                        .darkred_btn {
                            width: 100%;
                            @media (max-width: 767px) {
                                font-size: 18px;
                                line-height: 16px;
                                height: 48px;
                            }
                        }
                        .footer_signup {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17.05px;
                            text-align: left;
                            color: #5d5d70;
                            padding-top: 32px;
                            text-align: center;
                            margin: 0 auto;
                            span {
                                color: #000000;
                            }
                        }
                    }
                    .btn-close {
                        display: none;
                    }
                }
            }
        }
    }
}
.booking_drawer{
    @media (max-width:575px) {
        .filter_box{
            &.filterOpen{
                 .filter_modal_content {
                    transform: translateX(50%);
                    right: 50%;
                    max-width: 343px;
                    top: 55px;
                }
            }
        }
    }
    .filter_modal{
        display: none;
    }
    .filter_modal_content {
        &.booking_cart {
            max-width: 445px;
            height: auto;
            top: 59px;
            position: absolute;
            z-index: 99999;
            @media (max-width: 767px) {
                top: 49px;
            }
            .filter_modal_head {
                padding: 32px 32px 24px 32px;
                border-bottom: none;
                @media (max-width:575px) {
                    padding: 16px 16px 24px 16px;
                }
                    h2{
                        line-height: 29.23px;
                    } 
            }
            .product_wrapper{
                overflow: auto;
                max-height: 496px;
            }
            .booking_cart_product {
                padding-bottom: 24px;
                margin: 0px 32px;
                border-bottom: 1px solid #b9b8c1;
                display: flex;
                align-items: center;
                gap: 28px;
                justify-content: space-between;
                padding-top: 24px;
                &:first-child{
                    padding-top: 0px;
                }
                @media (max-width:575px) {
                    gap: 16px;
                    align-items: flex-start;
                    flex-direction: column;
                    margin: 0px 16px;
                }
                .product_compare {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    img {
                        width: 39px;
                    }
                    .pro_order {
                        margin-bottom: 0px;
                        .product_name {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 16px;
                            text-align: left;
                            color: #868594;
                            margin-right: 8px;
                        }
                        .product_id {
                            font-size: 18px;
                            font-weight: 700;
                            line-height: 18px;
                            text-align: left;
                            font-family: "Proxima Nova Bold";
                            color: $dark_blue;
                        }
                    }
                    .gpm {
                        margin-bottom: 0px;
                        span {
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 17.05px;
                            text-align: left;
                            color: #000000;
                            margin-right: 20px;
                        }
                    }
                }
                .qty_carts {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 8px;
                    @media (max-width:575px) {
                        justify-content: space-between;
                        align-items: center;
                        width: 82%;
                        margin-left: auto;
                    }
                    .available_carts {
                        border: 1px solid #E2E3E4;
                        padding-left: 6px;
                        input{
                            border: none;
                            width: auto;
                            min-width: auto;
                            max-width: 33px;
                            color: #000000;
                            font-size: 14px;
                            &:focus-visible{
                                border: none;
                                outline: none;
                            }
                        }
                        .form-row {
                            margin-bottom: 0px;
                            height: 29px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                        }
                        select {
                            padding: 2px 6px;
                            height: 28px;
                            margin-right: 8px;
                            min-width: 65px;
                            max-width: 65px;
                            font-size: 14px;
                            font-weight: 400;
                            color: $dark_blue;
                            line-height: 24px;
                            letter-spacing: 0.01em;
                            text-align: left;
                            text-transform: unset !important;
                            background-image: url("../../images/darker_down.svg");
                            background-size: 9px !important;
                            background-position: right 0.37rem center;
                            color: #000000;
                        }
                    }
                    .red_cross {
                        height: 29px;
                    }
                }
            }
            .filter_modal_body {
                padding: 24px 32px 32px 32px;
                @media (max-width:575px) {
                    padding: 24px 16px 16px 16px;
                }
                .booking_fields {
                    label {
                        line-height: 17.05px;
                        display: block;
                        width: 100%;
                        text-align: left;
                    }
                    .fields-group{
                        @media (max-width:575px) {
                            .form-row{
                                margin-bottom: 20px;
                            }
                        }
                    }
                }
                button {
                    margin-top: 32px;
                    background-color: #ED1D24;
                    &:hover{
                        background-color: $white;
                        border: 1px solid #ED1D24;
                        color: #ED1D24;
                        svg{
                            path{
                                stroke: #ED1D24;
                            }
                        }
                    }
                }
                .cart_heading {
                    padding-bottom: 24px;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24.36px;
                    text-align: left;
                    color: $dark_blue;
                    font-family: "Proxima Nova Bold";
                    margin-bottom: 0px;
                }
            }
            button{
                &.darkred_btn{
                    margin: 32px;
                    max-width: 380px;
                    @media (max-width: 575px) {
                        margin: 16px;
                        max-width: 311px;
                    }
                }
            }
        }
    }
}
.myOrder_logout{
    position: absolute;
    right: 0px;
    top: 1px;
    padding: 24px;
    border: 1px solid #E2E3E4;
    width: 100%;
    max-width: 299px;
    min-width: 299px;
    box-shadow: 0px 0px 24px 0px #00000017;
    z-index: 99;
    background-color: $white;
    @media (max-width: 991px) {
        min-width: 181px;
        padding: 14px;
    }
    .order_link{
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: $dark_blue;
        font-family: "Proxima Nova Bold";
        padding-bottom: 16px;        
        margin-bottom: 16px;
        border-bottom: 1px solid #B9B8C1;
        text-decoration: none;
        display: block;
    }
    .logout_btn{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        .log_out_link{
            text-decoration: none;
            font-size: 18px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: $dark_blue;
            font-family: "Proxima Nova Bold";
            color: #ED1D24;
        }
    }
}
.product_compare{
    &.order{
        display: flex;
        align-items: center;
        gap: 22px;
        img{
            width: 61px;
            height: auto;
        }
        .pro_order {
            margin-bottom: 0px;
            .product_name {
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                color: #868594;
                margin-right: 8px;
            }
            .product_id {
                font-size: 18px;
                font-weight: 700;
                line-height: 18px;
                text-align: left;
                font-family: "Proxima Nova Bold";
                color: $dark_blue;
            }
        }
        .gpm {
            margin-bottom: 0px;
            span {
                font-size: 14px;
                font-weight: 400;
                line-height: 17.05px;
                text-align: left;
                color: #000000;
                margin-right: 20px;
            }
        }
    }
}
.order_status_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    min-width: 124px;
    gap: 10px;
    border: none;
    padding: 0px 12px;
    .status_dot_show{
        width: 8px;
        height: 8px;
        border-radius: 10px;
    }
    .current_status{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: $dark_blue;
    }
}
.booking_cart_open{
    .filterOpen{
         .overlay{
            position: fixed;
            z-index: 9999;
         }
         .filter_modal{
            display: block;
        }
    }
}
.logout_popup{
    .dashboard_page_main{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: #00000036;
            width: 100%;
            height: 100vh;
            min-height: 100%;
            display: block;
            z-index: 9;
            @media (max-width: 991px) {
                display: none;
            }
        }
    }
}
@media (max-width: 991px) {
    .i_pad_50{
        max-width: 50% !important;
        @media (max-width: 767px) {
            max-width: 100% !important;
        }
    }
    .none_991{
        display: none;
    }
}
.p_3 {
    padding:3px;
  }
  
.custom_dataTable{
     .p-datatable-wrapper{
         table{
             thead{
                 tr{
                     th{
                        &:last-child {
                            .p-column-header-content {
                                justify-content: flex-start;
                            }
                        }
                    }
                }
            }
        }
    }
  }
  
  .error_text { 
    color:red;
  }
  
  .ml_10 {
  margin-right: 10px;
  }
  
  .form-select{
    &:focus {
        border-color:none !important;
        outline: 0;
        box-shadow: none !important;
    }
  }
  
  .float_error {
    margin-top: -20px;
    position: absolute;
  }
  
  .mt_20 {
    margin-top:20px;
  }
  
  .combine_fields{
     .form-row {
         .single_box{
            background-color: #fff;
            width: 112px;
            border-right: 1px solid #e2e3e4;
        }
    }
  }
  
  .modal{
    &.show{
         .modal-dialog{
             .modal-content{
                .modal-body {
                    padding: 32px;
                }
            }
        }
    }    
  }
  
  .stock_table{
    &.custom_dataTable{
        .p-datatable-wrapper{
            table{
                thead{
                    tr{
                        th{
                            &:last-child{
                                .p-column-header-content {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            &:first-child {
                                padding-left: 20px;
                            }
                        }
                    }
                }
            }
        }
    } 
  }
  
  .stock_label_value {
    font-size: 21px;
    font-weight: 400;
    text-align: left;
  }
  .inner_child_col{
    position: relative;
    // @media (min-width: 992px) {
        .form-row{
            margin-bottom: 16px !important;
        }    
    // }
    .new_field_added{
        @media (min-width: 1100px) {
            position: absolute;
            width: 100%;
            left: 0px;
            padding: 0px 10px;
        }
        @media (max-width: 1100px) {
            margin-bottom: 24px;
        }

        .form-label, label{
            display: none;
        }
    }
  }


  .fileTypeField{
    .pdf_file_uploaded{
        .pdf_details_show{
            .pdf_name_size{
                .file_name {
                    color: #15223b;
                    font-family: "Proxima Nova Bold";
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    word-break: break-all;
                    width: 362px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
.text_dec{
    text-decoration: none;
}
.booking_cart_open{
    &.modal-open{
        .fade {
            &.modal-backdrop{
                z-index: 99999;
            }
        }
        .confirm_msg{
            text-align: center;
        }
        .modal-footer{
            justify-content: center;
        }
    }
}
// @media (max-width: 767px) {
//     input[type=number]::-webkit-inner-spin-button, 
//     input[type=number]::-webkit-outer-spin-button {  
//     opacity: 1;
//     }
// }


.cart_icon{
    position: relative;
    cursor: pointer;
    .booking_count{ 
        position: absolute;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F57E20;
        font-size: 12px;
        font-weight: 400;
        line-height: 13.52px;
        text-align: left;
        top: -1px;
        right: -9px;
        border-radius: 100px;
    }
}
.available_carts{
    .cart_icon{
        .booking_count{ 
            top: -9px;
        }
    }
}
.new_update_pumps{
    .fire-select-heading{
        font-size: 48px;
        @media (max-width: 991px) {
            font-size: 28px;
        }
    }
}
.flow_select_link{
    position: absolute;
    top: 128px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    // color: $dark_blue;
    @media (max-width: 991px) {
        top: 50px;
        font-size: 14px;
    }
}