.sales_order_tracking {
    .order_search_listing {
        padding-top: 53px;
        padding-right: 20px;
        padding-left: 0px;
        width: 26.9%;
        height: 100vh;
        overflow: hidden;
        @media (max-width: 991px) {
            width: 40%;
            padding-left: 12px;
        }
        @media (max-width: 767px) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            padding-top: 24px;
            height: auto;
        }
        .table_heading_content {
            margin-bottom: 20px;
        }
        .sales_left_heading {
            @include font_30_700;
            line-height: 30px;
            color: $dark_blue;
            @media (max-width: 1199px) {
                font-size: 24px;
            }
        }
        .search_field_block {
            margin-right: 0px;
            margin-bottom: 20px;
            .search_heading {
                @include font_18_700;
                color: $dark_blue;
                line-height: 18px;
                font-weight: 700;
                margin-bottom: 18px;
            }
            .form-row {
                position: relative;
                .search_svg {
                    top: 7px;
                }
                .search_field {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }
    .order_details_col {
        width: 73.1%;
        padding-left: 0px;
        padding-right: 0px;
        position: relative;
        height: 100vh;
        @media (max-width: 991px) {
            width: 60%;
            height: auto;
        }
        @media (max-width: 767px) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
        }
        &::before{
            content: "";
            border-right: 1px solid #e2e3e4;
            width: 1px;
            height: 100vh;
            position: absolute;
            left: 0px;
            top: 0px;
            @media (max-width: 991px) {
                height: 100%;
            }
            @media (max-width: 767px) {
                display: none;
            }
        }
        .table_heading_content {
            margin-bottom: 0px;
        }
        .track_order_details {
            padding: 62px 5px 40px 32px;
            border-top: 1px solid #e2e3e4;
            border-bottom: 1px solid #e2e3e4;
           
            @media (max-width: 767px) {
                padding: 24px 0px;
            }
            .details_block {
                padding: 0px;
                margin-bottom: 0px;
                border: none;
                .max_w_inner_310 {
                    .details_data {
                        max-width: 310px;
                        @media (max-width: 991px) {
                            max-width: 100%;
                        }
                    }
                }
                .max_w_inner_275 {
                    .details_data {
                        max-width: 275px;
                        @media (max-width: 991px) {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        .sales_track_status {
            .trackStatus_block {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                padding: 40px 5px 40px 32px;
                @media (max-width: 991px) {
                    border-bottom: 0px;
                }
                @media (max-width: 767px) {
                    border-bottom: 0px;
                    padding: 24px 0px;
                }
                .sub_details_head {
                    @include font_24_400;
                    color: $shadeGrey;
                    line-height: normal;
                    font-weight: 400;
                    text-transform: capitalize;
                }
                .track_flow {
                    margin-top: 19px;
                    .won {
                        width: 391px;
                    }
                    .dispatched {
                        width: 300px;
                    }
                    .progress_bar {
                        @media (max-width: 991px) {
                            flex-direction: column;
                        }
                        li {
                            row-gap: 12px;
                            flex-direction: column;
                            @media (max-width: 991px) {
                                flex-direction: row;
                                columns: 12px;
                            }
                            .step_data {
                                margin-bottom: 10px;
                                @media (max-width: 991px) {
                                    margin-bottom: 20px;
                                }
                            }
                            &::after {
                                @media (max-width: 991px) {
                                    left: 8px;
                                    top: -98px;
                                    border-bottom: 0px;
                                    border-left: 3px dashed #c9cacc;
                                    height: 100%;
                                }
                            }
                            &.active {                               
                                &::after {
                                    border-bottom: 3px solid #f57e20;
                                    z-index: 1;
                                    width: 100%;

                                    @media (max-width: 991px) {
                                        height: 100%;
                                        border-bottom: 0px;
                                        border-left: 3px solid #f57e20;
                                        top: -82px;
                                        left: 8px;
                                        width: 100%;
                                    }
                                    @media (max-width: 767px) {
                                        top: -100px;
                                    }
                                }
                            }
                            &.won {
                                &::after {
                                    width: 220%;
                                    left: -233%;
                                    @media (max-width: 991px) {
                                        left: 8px;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        span.fix_border {
                            display: none;

                            @media (max-width: 991px) {
                                border-bottom: 0px;
                                height: 100%;
                                left: 8px;
                                width: 1px;
                                border-left: 3px dashed #c9cacc;
                            }
                        }
                    }
                }
            }
            .download_btn{
                padding: 7px 10px;
                height: 30px;
                width: 97px;
                @include font_12_700;
                gap: 5px;
                svg{
                    margin-left: 0px;
                    margin-right: 0px;
                    min-width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .search_orders_listing{
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        margin-top: 20px;
        max-height: 100vh;
        overflow: hidden;
        @media (max-width: 767px) {
            height: auto;
            max-height: 350px;
            overflow: auto;
        }
        li{
            .order_search_items{
                padding: 20px;
                border-bottom: 1px solid #E2E3E4;
                text-decoration: none;
                display: flex;
                flex-direction: column;
                gap: 8px;
                .order_id_heading{
                    @include font_14_700;
                    color: $dark_blue;
                    line-height: normal;
                }
                .order_location{
                    @include font_12_400;
                    color: $dark_blue;
                    line-height: 12px;
                }
                .order_purchaser{
                    @include font_12_400;
                    color: $dark_blue;
                    line-height: 12px;
                }
                &.active{
                    background-color: #ECCABF;
                    border-bottom: 1px solid #ECCABF;
                }
            }
        }
    }
}
